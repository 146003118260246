.mainSlider .swiperContainer {
  background-image: url("../public/images/slider/slide1.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer .swipermain {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.mainSlider .swiperContainer .swipermain .textDesign {
  background-color: none;
}

.mainSlider .swiperContainer .swipermain .textDesign h3 {
  color: aqua;
  font-size: 30px;
}

.mainSlider .swiperContainer .swipermain .textDesign p {
  color: white;
  font-size: 17px;
}

.mainSlider .swiperContainer2 {
  background-image: url("../public/images/slider/slide2.jpg");
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.mainSlider .swiperContainer2 .swipermain2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 750px;
}

.mainSlider .swiperContainer2 .swipermain2 .textDesign2 {
  background-color: none;
}

.mainSlider .swiperContainer2 .swipermain2 h3 {
  color: aqua;
  font-size: 30px;
}

.mainSlider .swiperContainer2 .swipermain2 p {
  color: white;
  font-size: 17px;
}

.carouselpopuphide {
  text-align: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.4;
  padding-bottom: 10px;
}
.ReactModal__Overlay--after-open
{
  z-index: 1000;
}
@media screen and (max-width: 770px) {
  .mainSlider .swiperContainer {
    height: 400px;
  }

  .mainSlider .swiperContainer .swipermain {
    height: 400px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign h3 {
    font-size: 30px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign p {
    font-size: 20px;
  }

  .mainSlider .swiperContainer2 {
    height: 400px;
  }

  .mainSlider .swiperContainer2 .swipermain2 {
    height: 400px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 h3 {
    font-size: 30px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 p {
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .mainSlider {
    margin-top: 0px;
  }

  .mainSlider .swiperContainer {
    height: 500px;
  }

  .mainSlider .swiperContainer .swipermain {
    height: 500px;
  }

  .mainSlider .swiperContainer2 {
    height: 500px;
  }

  .mainSlider .swiperContainer2 .swipermain2 {
    height: 500px;
  }
}

@media screen and (max-width: 420px) {
  .mainSlider {
    margin-top: 0px;
  }

  .mainSlider .swiperContainer {
    height: 400px;
  }

  .mainSlider .swiperContainer .swipermain {
    height: 640px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign h3 {
    font-size: 22px;
    margin-top: 14px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign p {
    font-size: 18px;
    text-align: center;
  }

  .mainSlider .swiperContainer2 {
    height: 400px;
  }

  .mainSlider .swiperContainer2 .swipermain2 {
    height: 640px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 h3 {
    font-size: 22px;
    margin-top: 14px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 p {
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: 385px) {
  .mainSlider {
    margin-top: 0px;
  }

  .mainSlider .swiperContainer {
    height: 300px;
  }

  .mainSlider .swiperContainer .swipermain {
    height: 450px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign h3 {
    font-size: 20px;
    margin-top: 10px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign p {
    font-size: 16px;
    text-align: center;
  }

  .mainSlider .swiperContainer2 {
    height: 300px;
  }

  .mainSlider .swiperContainer2 .swipermain2 {
    height: 450px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 h3 {
    font-size: 20px;
    margin-top: 10px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 p {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  /* .mainSlider {
  } */
  .mainSlider .swiperContainer {
    height: 300px;
  }

  .mainSlider .swiperContainer .swipermain {
    height: 450px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign h3 {
    font-size: 19px;
  }

  .mainSlider .swiperContainer .swipermain .textDesign p {
    font-size: 15px;
  }

  .mainSlider .swiperContainer2 {
    height: 300px;
  }

  .mainSlider .swiperContainer2 .swipermain2 {
    height: 450px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 {
    background-color: black;
    width: 100%;
    margin-top: 50px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 h3 {
    font-size: 19px;
  }

  .mainSlider .swiperContainer2 .swipermain2 .textDesign2 p {
    font-size: 15px;
  }
}

/* ================= service page css================= */
.section-big {
  margin-top: 30px;
}

ul.i-list {
  padding-left: 0;
  list-style: none;
}

ul.i-list .list-title {
  display: inline-block;
  position: absolute;
}

ul.i-list li {
  padding: 2px 0px;
}

ul.i-list i.fa {
  margin-right: 7px;
}

ul.i-list .list-item {
  margin-top: 3px;
  display: inline-block;
}

ul.i-list.filled i.fa {
  color: white;
  font-size: 9px;
  padding: 5px;
  border-radius: 50%;
}

ul.e-icon-list.filled li {
  padding: 2px 0px;
  line-height: 24px;
}

ul.i-list.underline li {
  padding: 6px 0px;
  border-bottom: 1px solid #eee;
}

ul.i-list.medium li {
  padding-bottom: 2px;
  position: relative;
}

ul.i-list.medium .icon {
  margin-right: 25px;
  color: white;
  font-size: 25px;
  text-align: center;
  line-height: 68px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 1;
  /*background-image: url(../img/crease.svg) !important;
  -moz-background-size: 100% 100% !important;
  background-size: 100% 100% !important;
  background-position: center center !important;*/
}

ul.i-list.medium .icon i.fa {
  margin: 0;
}

ul.i-list.medium.bordered .icon {
  background: white;
  color: inherit;
  border: 2px solid #8fc135;
  font-size: 26px;
  color: #8fc135;
  position: relative;
  z-index: 1;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.28);
}

ul.i-list.medium .list-item {
  text-transform: uppercase;
}

ul.i-list.large .icon {
  margin-right: 30px;
  background: #d0d0d0;
  color: white;
  font-size: 30px;
  text-align: center;
  line-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.28);
}

ul.i-list.large .icon i.fa {
  margin: 0;
}

ul.i-list.large.bordered .icon {
  background: inherit;
  color: inherit;
  border: 2px solid #8fc135;
  font-size: 30px;
  color: #8fc135;
}

ul.i-list.large .list-item {
  text-transform: uppercase;
}

ul.i-list .icon {
  float: left;
}

ul.i-list.right {
  text-align: right;
}

ul.i-list.right .icon {
  float: right;
}

ul.i-list.right .icon {
  float: right;
  margin-right: 0;
  margin-left: 25px;
}

ul.i-list.large.right .icon {
  float: right;
  margin-right: 0;
  margin-left: 30px;
}

ul.i-list.large li {
  margin-bottom: 25px;
}

ul.i-list .icon-content {
  overflow: hidden;
}

ul.i-list .icon-content .title {
  margin-top: 5px;
  margin-bottom: 10px;
}

.left-line .iconlist-timeline {
  left: auto;
  right: 35px;
}

.iconlist-timeline {
  position: absolute;
  top: 1%;
  left: 32px;
  width: 1px;
  height: 99%;
  border-right-width: 1px;
  border-right-style: dashed;
  height: 100%;
  border-color: #ccc;
}

.icon {
  background-color: #d5ac63;
}

separator,
.testimonial-two,
.exp-separator-inner {
  border-color: #0cb4ce;
}

.exp-separator {
  border-color: #0cb4ce;
  border-top-width: 2px;
  margin-top: 10px;
  margin-bottom: 2px;
  width: 100%;
  max-width: 55px;
  border-top-style: solid;
  height: auto;
  clear: both;
  position: relative;
  z-index: 11;
}

.section-sub-title {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: Poppins;
}

.section-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 0.45em;
  margin-bottom: 0.35em;
  color: #303133;
  font-family: Poppins;
  letter-spacing: -0.02em;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.text-center {
  text-align: center !important;
}

.row .col-md-3 .single-working-item .text-box .forleft {
  text-align: left !important;
}

.center-separator .exp-separator-inner,
.center-separator.exp-separator {
  margin-left: auto;
  margin-right: auto;
}

.bold {
  font-weight: 500;
}

.bold1 {
  font-weight: 300;
  color: white;
}

/* Model Css */

.modal-image {
  background-color: #000;
  height: 100vh;
  color: gray;
}

.modal-image .modal-image-header {
  border-bottom: 1px solid gray;
  display: flex;
  padding: 0px 10px;
  line-height: 35px;
}

.modal-image .card-footer .card-footer-leftpanel {
  flex: 50%;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-image .card-footer .card-footer-rightpanel {
  flex: 50%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  gap: 50px;
  align-items: center;
  padding: 0 15px;
}

.modal-image .card-footer .card-footer-rightpanel button {
  background-color: unset;
  /* font-size: 35px; */
  color: #fff;
  outline: none;
  border: none;
}

.right-panel-border {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 0px 20px;
  color: #0078db;
  background-color: #333333;
  align-items: center;
  display: flex;
  justify-content: center;
}

.right-panel-border-2 {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 0px 20px 0px 40px;
  color: #0078db;
  background-color: #333333;
}

.modal-image .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image .card-body .image-panel {
  width: 70%;
  height: 68vh;
  position: relative;
}

.image-operator {
  position: absolute;
  color: white;
  top: 40%;
  /* left: 45%; */
  height: 50px;
  z-index: 100;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #0078db; */
}

.footer-image {
  display: flex;
  flex-direction: column;
  /* border: 2px solid gray; */
  height: 22vh;

  /* align-items: center; */
}

.footer-image .footer-img-scroll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.footer-img-scroll > div {
  /* height: 15vh; */
  /* width: 120px; */
  gap: 8px;
  /* border: 1px solid gray; */
  /* background-color: #0078db; */
  margin: 15px;
}

.footer-image img {
  height: 80px;
  width: 80px;
  opacity: 0.8;
  display: none;
  object-fit: cover;
  display: block;
  border: 2px solid gray;
}

.footer-image img:hover {
  opacity: 1;
  border: 2px solid #0078db;
}

.model {
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.popapcontainer {
  position: relative;
  background: #8fc135;
}

.popapcontainer .modalmainnew {
  position: absolute;
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999 !important;
  scroll-behavior: smooth;
}

/* .modalmainnew {
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 999999 !important;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .modalmainnew .model-wrappernew {
  background: #fff;
  width: 1000px;
  height: 500px;
  top: 30px;
  left: 150px;
  right: 150px;
  position: absolute;
  border-radius: 5px;
  padding: 20px;
} */

.close {
  margin-left: 238px;
  margin-bottom: 15px;
}




a {
  text-decoration: none;
  display: inline-block;
  padding: 4px 10px;
}

a:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #d5ac63;
  color: white;
}

.round {
  border-radius: 50%;
}